import Typography from '@mui/material/Typography';
import React from 'react';
import { formatDayMonthYearSlash, formatMoney } from '../../../helpers';
import CessionStatus from '../CessionStatus';
import dayjs from '../../../config/dayjs';

export default [
  {
    field: 'folio',
    headerName: 'Folio',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography alignSelf="start">{row.folio}</Typography>
    ),
    width: 80,
  },
  {
    field: 'dateIssued',
    headerName: 'Fecha de emisión',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography alignSelf="start">
        {formatDayMonthYearSlash(dayjs(row.dateIssued))}
      </Typography>
    ),
    width: 40,
  },
  {
    field: 'expirationDate',
    headerName: 'Fecha de vencimiento',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography alignSelf="start">
        {formatDayMonthYearSlash(dayjs(row.expirationDate))}
      </Typography>
    ),
    width: 40,
  },
  {
    field: 'amountWithIva',
    headerName: 'Monto factura',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography alignSelf="start">
        ${formatMoney(row.amountWithIva)}
      </Typography>
    ),
    width: 80,
  },
  {
    field: 'cessionStatus',
    headerName: 'Estado',
    sortable: true,
    renderCell: ({ row }) => <CessionStatus statusInfo={{ [row.status]: 1 }} />,
    width: 40,
  },
];

const MuiDataGrid = {
  styleOverrides: {
    root: {
      border: 'none',
    },
    columnHeaders: {
      border: 'none',
    },
    row: ({ theme }) => ({
      backgroundColor: '#f9f9f9',
      verticalAlign: 'top',
      marginBottom: 8,
      borderRadius: 12,
      '&$selected': {
        backgroundColor: theme.palette.primary.A200,
      },
      '&$selected:hover': {
        backgroundColor: theme.palette.primary.A200,
      },
    }),
    cell: {
      border: 'none',
      overflow: 'auto !important',
      whiteSpace: 'initial !important',
      '&:focus': {
        outline: 'none',
      },
      '&:focus-within': {
        outline: 'none',
      },
    },
    footerContainer: {
      justifyContent: 'center',
    },
  },
};

export default MuiDataGrid;

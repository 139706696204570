const typography = {
  fontFamily: 'Plus Jakarta Sans',
  xlarge_2: {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: '140%',
  },
  xlarge_1: {
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '140%',
  },
  large_3: {
    fontSize: 32,
    fontWeight: 700,
    lineHeight: '140%',
  },
  large_2: {
    fontSize: 28,
    fontWeight: 600,
    lineHeight: '140%',
  },
  large_1: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '140%',
  },
  medium_3: {
    fontSize: 22,
    fontWeight: 600,
    lineHeight: '140%',
  },
  medium_2: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '140%',
  },
  medium_1: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '140%',
  },
  small_3: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '140%',
  },
  small_2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '140%',
  },
  small_1: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '140%',
  },
  h1: {
    fontWeight: 'bold',
    fontSize: 25,
    lineHeight: 1,
  },
  h2: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 1.1,
  },
  h3: {
    fontWeight: '500',
    fontSize: 25,
    lineHeight: 1.1,
  },
  h4: {
    fontWeight: '400',
    fontSize: 20,
    lineHeight: 1.2,
  },
  h5: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 1.2,
  },
  h6: {
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 1.2,
  },
  h7: {
    fontWeight: '500',
    fontSize: 20,
    lineHeight: 1.2,
  },
  body1: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 1.2,
  },
  body2: {
    fontSize: '12',
    fontWeight: '300',
    lineHeight: 1.25,
  },
  caption: {
    fontSize: 12,
    lineHeight: 1.2,
    color: '#ABABAB',
  },
  button: {
    textTransform: 'none',
    height: 50,
    fontSize: 14,
  },
  subtitle1: {
    height: 'auto',
    fontSize: 12,
    WebkitLineClamp: 2,
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    lineHeight: '0.8rem !important',
  },
  subtitle3: {
    height: 'auto',
    fontSize: 11,
    WebkitLineClamp: 2,
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    lineHeight: '0.8rem !important',
    fontWeight: 400,
  },
  formLabel: {
    fontSize: '14',
    fontWeight: '400',
    display: '-webkit-box',
    marginBottom: '5px',
  },
  cardHeader: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: 1.2,
  },
  subNavItem: {
    fontWeight: '200',
    fontSize: 13,
    lineHeight: 1.1,
  },
  info: {
    maxWidth: 200,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    fontWeight: 500,
  },
  error: {
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: 500,
  },
  h1v2: {
    fontSize: 20,
    lineHeight: '120%',
    fontWeight: 700,
    '@media (min-width:900px)': {
      fontSize: 26,
    },
    '@media (min-width:1200px)': {
      fontSize: 34,
    },
  },
  h2v2: {
    fontSize: 22,
    lineHeight: '120%',
    fontWeight: 700,
    '@media (min-width:900px)': {
      fontSize: 28,
    },
    '@media (min-width:1200px)': {
      fontSize: 34,
    },
  },
  h3v2: {
    fontSize: 80,
    fontWeight: 800,
    lineHeight: '100px',
  },
  subtitle1v2: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: 3.6,
  },
  subtitle2v2: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '150%',
    '@media (min-width:1200px)': {
      fontSize: 20,
    },
  },
  subtitle3v2: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 1.2,
    '@media (min-width:900px)': {
      fontSize: 26,
    },
  },
  buttonTextv2: {
    fontSize: 14,
    fontWeight: 800,
    lineHeight: '150%',
    '@media (min-width:1200px)': {
      fontSize: 20,
    },
  },
  footerTitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: 1.1,
    '@media (min-width:900px)': {
      fontSize: 25,
    },
  },
  footerSubtitle: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 0.8,
  },
  footerText: {
    fontWeight: 500,
    fontSize: 11,
    lineHeight: 1.57,
  },
};

export default typography;

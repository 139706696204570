const MuiLink = {
  styleOverrides: {
    root: ({ theme }) => ({
      cursor: 'pointer',
      '&[disabled]': {
        color: theme.palette.gray.A600,
        pointerEvents: 'none',
      },
    }),
  },
};

export default MuiLink;

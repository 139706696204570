const MuiTableRow = {
  styleOverrides: {
    root: ({ theme }) => ({
      height: 40,
      backgroundColor: '#f9f9f9',
      verticalAlign: 'top',
      marginBottom: 8,
      borderRadius: 12,
      '&$selected': {
        backgroundColor: theme.palette.primary.A200,
      },
      '&$selected:hover': {
        backgroundColor: theme.palette.primary.A200,
      },
    }),
    head: {
      backgroundColor: 'transparent',
      height: 'inherit',
      borderTop: 'none',
    },
  },
};

export default MuiTableRow;
